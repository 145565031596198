<template>
	<v-row>
		<BottomNavigationContainer v-if="menu.length > 1" :menu="menu" />

		<v-col cols="12" class="d-none d-md-block">
			<ToolbarContainer :menu="menu" />
			<v-toolbar elevation="1" height="25" rounded="b">
				<Breadcrumbs />
			</v-toolbar>
		</v-col>

		<v-col cols="12" lg="12">
			<router-view />
		</v-col>
	</v-row>
</template>

<script>
import Breadcrumbs from "../Widgets/Breadcrumbs";
import ToolbarContainer from "../Widgets/ToolbarContainer";
import BottomNavigationContainer from "../Widgets/BottomNavigationContainer";

export default {
	name: "DepartamentoPessoalContainer",
	components: { Breadcrumbs, ToolbarContainer, BottomNavigationContainer },
	data: () => ({
		menu: [],
	}),
	methods: {
		montarMenu() {
			let m = [];

			if (this.permissao("departamento_pessoal_dashboard_turnover")) {
				m.push({
					titulo: "* Turnover",
					titulo_app: "Turnover",
					icon: "mdi-account-convert-outline",
					to: "/departamento-pessoal",
					tooltip: "Dashboard e gerenciador de turnover",
				});
			}

			if (this.permissao("departamento_pessoal_dashboard_estatisticas")) {
				m.push({
					titulo: "* Estatísticas (validar)",
					titulo_app: "Estatísticas",
					icon: "mdi-chart-box-outline",
					to: "/departamento-pessoal/estatisticas",
					tooltip: "Dashboard de estatísticas dos colaboradores",
				});
			}

			if (this.permissao("departamento_pessoal_qtd_funcionarios")) {
				m.push({
					titulo: "* Quadro Funcionários (validar)",
					titulo_app: "Funcionários",
					icon: "mdi-account-box-outline",
					to: "/departamento-pessoal/quadro-funcionarios",
					tooltip:
						"Dashboard e gerenciador de colaboradores fixos e atuais",
				});
			}

			if (this.permissao("departamento_pessoal_dashboard_hora_extra")) {
				m.push({
					titulo: "* Hora extra",
					titulo_app: "Hora extra",
					icon: "mdi-account-clock-outline",
					to: "/departamento-pessoal/hora-extra",
					tooltip: "Dashboard de horas extras",
				});
			}

			if (this.permissao("departamento_pessoal_exportador_senior")) {
				m.push({
					titulo: "Exportador Sênior",
					titulo_app: "Exportador",
					icon: "mdi-folder-download-outline",
					to: "/departamento-pessoal/exportador-senior",
					tooltip: "Exportador de salários Sênior",
				});
			}

			if (this.permissao("departamento_pessoal_importador_senior")) {
				m.push({
					titulo: "Importador salários",
					titulo_app: "Importador",
					icon: "mdi-folder-download-outline",
					to: "/departamento-pessoal/importador-salarios",
					tooltip: "Importador de salários Sênior",
				});
			}

			if (this.permissao("departamento_pessoal_listar")) {
				m.push({
					titulo: "Departamentos",
					titulo_app: "Departamentos",
					icon: "mdi-account-group-outline",
					to: "/departamento-pessoal/lista",
					tooltip: "Lista de departamentos do sistema Gestor",
				});
			}

			this.menu = m;
		},
	},
	mounted() {
		this.montarMenu();
	},
};
</script>

<style scoped>
</style>